import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/RCM.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/kontakt'
  }

  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <Title>Što je hotel channel manager?</Title>
          <Text>
            Hotel channel manager je softversko rješenje koje hotelijerima omogućuje upravljanje prodajom soba i
            cijenama na više OTA kanala poput Booking.com-a, Expedia-e, Airbnb-a i drugih. Integrirani channel manager i
            property management sustav (hotel PMS), hotelijerima omogućuje ažuriranje cijena i dostupnosti u stvarnom
            vremenu.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Dogovori sastanak</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
