import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import HBEbe from '../../../assets/images/HBEbe.png'
import HCMrcm from '../../../assets/images/HCMrcm.png'
import Kokpit from '../../../assets/images/Kokpit.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import bookingengine from '../../../assets/images/RCM.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Kako funkcionira hotel channel manager?'
  },
  {
    id: 2,
    title: 'Prednosti korištenja hotel channel managera'
  }
]

const Content = ({ locale }) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>Teme unutar stranice</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2 id="1" className="sections">
          Kako funkcionira hotel channel manager
        </H2>
        <P>
          <a href="https://rentl.io/channel-manager-hrvatska">Hotel channel manager</a> funkcionira integrirajući se s{' '}
          <a href="https://rentl.io/hotel-property-management-system">hotelskim property management sustavom (PMS)</a> i
          povezivanjem različitih online distribucijskih rezervacijskih kanala. Property management sustav je centralni
          sustav kojim upravljate svim hotelskim operacijama poput rezervacije soba, održavanjem i naplatom.{' '}
          <a href="https://rentl.io/channel-manager-hrvatska">Channel manager integrira se s PMS-om</a> kako bi ostvario
          pristup potpunom popisu soba i cijenama, koje zatim ažurira na povezanim OTA kanalima.
        </P>

        <P>
          Kada gost rezervira na jednom od povezanih booking kanala, channel manager ažurira zauzeće u stvarnom vremenu,
          kako rezervirana soba ili smještajna jedinica više ne bi bila dostupna na drugim kanalima. Tako se sprječava
          overbooking i dvostruke rezervacije koje mogu uzrokovati probleme i hotelskom osoblju i gostu.
        </P>
        <P>
          Hotel channel manager omogućuje hotelijerima postavljanje različitih cijena i restrikcija za svaki pojedini
          OTA kanal, ovisno o visini provizije i drugim čimbenicima. To pomaže hotelima optimizirati strategiju
          distribucije u svrhu povećanja prihoda ciljanjem različitih segmenata gostiju kroz različite prodajne kanale.
        </P>

        <FullWidthImg>
          <img src={HCMrcm} />
        </FullWidthImg>
        <ImgTitle>Rentlio Channel Manager</ImgTitle>
        <GreyBlock>
          <H2 id="2" className="sections">
            Prednosti korištenja hotel channel managera
          </H2>
          <P>Nekoliko je prednosti korištenja hotel channel managera, više pročitajte u nastavku:</P>
          <H3>Štedi vrijeme i trud</H3>
          <P>
            Hotel channel manager štedi hotelijerima puno vremena automatizacijom procesa distribucije. Umjesto ručnog
            ažuriranja zauzeća i cijena na svakom OTA kanalu zasebno, channel manager ažurira sve u stvarnom vremenu na
            svim kanalima. Tako eliminira pogreške štedeći hotelijerima vrijeme koje mogu iskoristiti kako bi se
            usredotočili na druge važne aspekte poslovanja.
          </P>
          <H3>Sprječava overbooking i dvostruke rezervacije</H3>
          <P>
            Overbooking i dvostruke rezervacije jedan su od najčešćih problema s kojima se hoteli susreću pri
            upravljanju online distribucijom i zauzećem. Uz channel manager, zauzeće se ažurira u stvarnom vremenu na
            svim kanalima. Tako sobe i smještajne jedinica više nisu dostupne na drugim kanalima nakon što gost potvrdi
            rezervaciju, čime je mogućnost za overbooking ili dvostruke rezervacije značajno manja.
          </P>
          <H3>Povećava vidljivost i rezervacije</H3>
          <P>
            Hotel channel manager povećava vidljivost hotela i rezervacije povezivanjem s više online rezervacijskih
            kanala. To omogućuje hotelu doseg prema više potencijalnih gostiju uz ciljanje različitih segmenata. Channel
            manager omogućuje hotelijerima postavljanje različitih cijena i restrikcija za svaki OTA kanal, što pomaže
            pri optimizaciji strategije distribucije i povećanju prihoda.
          </P>
          <H3>Optimizira upravljanje prihodima</H3>
          <P>
            Hotel channel manager omogućuje dragocjene uvide u rezultate hotela na svakom OTA kanalu. Hotelijeri tako
            prate rezervacije, analizu učinka i donose analitički temeljene odluke kako bi optimizirali prodajnu
            strategiju i povećali prihode. Channel manager omogućuje hotelijerima dinamično postavljanje cijena na
            temelju potražnje i dostupnosti kako bi povećali prihode i ostvarili dobre rezultate.{' '}
          </P>
          <H3>Omogućuje kvalitetna iskustva za goste</H3>
          <P>
            Hotel channel manager omogućuje kvalitetna iskustva za goste točnim informacijama o stvarnoj dostupnosti
            soba i cijena. Gosti ako donose informirane odluke i rezerviraju sobe koje najbolje odgovaraju njihovim
            potrebama. Channel manager također omogućuje hotelima komunikaciju s gostima u stvarnom vremenu.
          </P>
          <FullWidthImg style={{ width: '80%' }}>
            <img src={bookingengine} />
          </FullWidthImg>
          <ImgTitle>Rentlio Channel Manager</ImgTitle>
        </GreyBlock>
        <Form locale="hr" />
        <H2>Otkrijte Rentlio Channel Manager</H2>
        <P>
          <a href="https://rentl.io/channel-manager-hrvatska">
            Integrirani Rentlio Property Management sustav i Channel Manager
          </a>{' '}
          omogućuje hotelijerima ažuriranje dostupnosti i cijena u stvarnom vremenu kako bi smještajne jedinice hotela
          uvijek bile dostupne na raznim povezanim OTA kanalima. Ažuriranje u realnom vremenu ujedno i eliminira rizik
          overbookinga i osigurava uvijek ispravno zauzeće na svim booking kanalima.
        </P>
        <P>
          Uz <a href="https://rentl.io/channel-manager-hrvatska">Rentlio Channel Manager</a>, hotelijeri mogu upravljati
          svojim rezervacijama iz jednog mjesta - centralnog Dashboarda, što olakšava praćenje popunjenosti i prihoda.
          Dodatno, Rentlio nudi naprednu statistiku i uvide u obrasce rezervacija, stope popunjenosti i trendove
          prihoda, čime hotelijeri jednostavnije prilagođavaju cijene i optimiziraju marketinške strategije.
        </P>
        <P>
          Generalno, Rentlio Channel Manager moćan je alat koji hotelijerima pomaže povećati vidljivost i rezervacije na
          više OTA kanala, istovremeno usmjeravajući operacije i maksimizirajući potencijal prihoda.
        </P>
        <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText>
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
