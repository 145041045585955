import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

import FAQauthority from '../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../components/GetStartedCTA'
import Banner from '../components/hotel-channel-manager/banner'
import Content from '../components/hotel-channel-manager/content'
const faqData = [
  {
    content:
      'Hotel channel manager softversko je rješenje koje hotelijerima omogućuje upravljanje zauzećem i cijenama na povezanim online distribucijskim kanalima poput online rezervacijskih kanala (OTA), metatražilica i vlastite web stranice. ',
    title: 'Što je hotel channel manager?'
  },
  {
    content:
      'Trošak channel managera uvelike varira o više čimbenika, uključujući broj kanala koje želite povezati, veličinu vašeg objekta te dodatnim funkcionalnostima koje su vam potrebne. Zatražite (personaliziranu) ponudu.',
    title: 'Koliko iznosi cijena hotel channel managera?'
  }
]
const cta_mobile_apps = {
  title: 'Želim isprobati Rentlio!  ?',
  description: 'Kreiraj svoj Rentlio račun, pozovi članove tima i uživaj u bržem, efikasnijem i fokusiranijem radu!'
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="hr">
      <SEO
        title="#1 Hotel Channel Manager"
        description="Otkrijte što je hotel channel manager i sve o prednostima integriranog property management sustava i channel managera."
        pathname={location.pathname}
        lang="hr"
        location={location}
        // schemaMarkup={schema}
      />
      <Header mode="dark" locale="hr" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHMS>
        <Banner />
        <Content locale="hr" />
      </WrapHMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="hr" location={location} />
    </Layout>
  )
}

export default HPMS
